<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card">
    <!--begin::Header-->
    <div class="card-header border-0 py-5 d-flex justify-content-between">
      <div
        class="font-weight-bolder text-primary text-uppercase font-size-h2"
        v-if="members_count == 0"
      >
        Twitter Users
      </div>
      <div
        class="font-weight-bolder text-primary text-uppercase font-size-h2"
        v-else
      >
        Twitter Users ({{ items.length }})
      </div>
      <div class="d-flex">
        <div>
          <b-button
            variant="primary"
            pill
            class="btn btn-primary py-2 mt-1 mr-4"
            @click="showModal"
          >
            New
          </b-button>
        </div>
        <b-form-input
          v-model="search_text"
          placeholder="Search..."
        ></b-form-input>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <ag-grid-vue
        rowHeight="65"
        :masterDetail="true"
        class="ag-theme-material ag-grid-vue"
        :columnDefs="columnDefs"
        :pagination="false"
        @grid-ready="onGridReady"
        :defaultColDef="defaultColDef"
         :detailCellRendererParams="detailCellRendererParams"
        :frameworkComponents="frameworkComponents"
        :rowData="items"
      >
      </ag-grid-vue>
    </div>
    <b-modal
      ref="my-modal"
      hide-footer
      :title="edit_or_create ? 'Add Twitter User' : 'Update Twitter User'"
    >
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="10" offset="1">
              <label>Twitter Username:</label>
              <b-form-input
                v-model="formData.name"
                placeholder="Twitter Username"
                class="modal-input"
                :disabled="!edit_or_create"
              ></b-form-input>
              <div
                v-if="errors && errors.name && errors.name.length >0"
              >
                <span
                  class="text-danger"
                  v-for="(error, i) in errors.name"
                  >{{ error }}</span
                >
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="10" offset="1">
              <label>Twitter Username:</label>
              <b-form-input
                v-model="formData.abbr"
                placeholder="abbreviation"
                class="modal-input"
              ></b-form-input>
              <div
                v-if="errors && errors.abbr && errors.abbr.length >0"
              >
                <span
                  class="text-danger"
                  v-for="(error, i) in errors.abbr"
                  >{{ error }}</span
                >
              </div>
            </v-col>
          </v-row>

           <v-row>
             <v-col cols="10" offset="1" class="pb-0 mb-0">
               <div class="d-flex">
                 <label>Pull From Twitter:</label>
                 <b-form-checkbox  v-model="formData.from_twitter_api" class="ml-2 "></b-form-checkbox>
               </div>
               <div v-if="errors && errors.from_twitter_api && errors.from_twitter_api.length >0">
                 <span class="text-danger" v-for="(error, i) in errors.from_twitter_api">{{ error }}</span>
               </div>
             </v-col>
           </v-row>

          <v-row>
             <v-col cols="10" offset="1" class="pb-0 mb-0">
               <div class="d-flex">
                 <label>Pull From WebArchive:</label>
                 <b-form-checkbox  v-model="formData.from_webarchive" class="ml-2 "></b-form-checkbox>
               </div>
               <div v-if="errors && errors.from_twitter_api && errors.from_twitter_api.length >0">
                 <span class="text-danger" v-for="(error, i) in errors.from_twitter_api">{{ error }}</span>
               </div>
             </v-col>
           </v-row>

        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" offset="1" style="text-align: right">
          <b-button
            variant="primary"
            pill
            :disabled="checkForm"
            @click="submitForm"
            >Submit</b-button>
        </v-col>
      </v-row>
    </b-modal>

    <b-modal ref="my-modal-2" title="Confirm">
      <div class="d-block text-center">
        <h3>Are you sure to delete this Twitter User?</h3>
      </div>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="success" @click="cancel()"> No </b-button>
        <b-button size="sm" variant="danger" @click="deleteConfirm()">
          Yes
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { AgGridVue } from 'ag-grid-vue';
import TuserActionsRenderer from './Render/TuserActionsRenderer.js';
import { LicenseManager } from "ag-grid-enterprise";
import 'ag-grid-enterprise';
import {
  CREATE_TUSER,
  UPDATE_TUSER,
  GET_TUSERS,
  DELETE_TUSER
} from "../../../../core/services/store/tuser.module";

LicenseManager.setLicenseKey("Servoy_B.V_Servoy_7Devs_200Deployment_11_October_2020__MTYwMjM3MDgwMDAwMA==26d908e26f73b44683ba7a5dfdd00755");

export default {
  name: "tusers",
  components: {
     AgGridVue,
  },
  data() {
    return {
      selectedSubmission: null,
      search_text: '',
      members_count: 0,
      deleteConfirmDialog: true,
      selectedPk: null,
      detailCellRendererParams: null,
      formData:{
        'name': null,
        'abbr': null,
        'from_twitter_api': null,
        'from_webarchive': null,
      },
      items: [],
      gridApi: null,
      columnApi: null,
      defaultColDef: {
        sortable: true,
        suppressMenu: true,
        flex: 1,
      },
      frameworkComponents : null,
      edit_or_create: true
    };
  },
  computed: {
    ...mapState({
      errors: state => state.tuser.errors
    }),
    ...mapGetters(["layoutConfig", "currentUserAccountInfo"]),
    checkForm(){
      return !(
          this.formData.name !== null &&
          this.formData.abbr !== null
      )
    },
    columnDefs(){
      return  [
        {
         headerName:'#',
         field: 'pk',
          maxWidth: 120,
         cellStyle: {
           textAlign: 'center',
         },
       },
       {
         headerName:'Twitter Username',
         field: 'name',
         cellStyle: {
           fontWeight: 500,
           textAlign: 'center',
         },
       },
      {
         headerName: 'Account Status',
         field: 'status',
         valueFormatter: statusFormatter,
         cellStyle: {
           textAlign: 'center',
         },
       },
      {
         headerName:'Abbreviation',
         field: 'abbr',
         cellStyle: {
           fontWeight: 500,
           textAlign: 'center',
         },
       },
       {
         headerName: 'Tweets Count From Twitter',
         field: 'tweets_count_from_twitter',
         cellStyle: {
           textAlign: 'center',
         },
       },

        {
         headerName: 'Tweets Count From Archive',
         field: 'tweets_count_from_archive',
         cellStyle: {
           textAlign: 'center',
         },
       },

        {
         headerName: 'Pull From Twitter API',
         field: 'from_twitter_api',
         valueFormatter: superUserFormatter,
         cellStyle: {
           textAlign: 'center',
         },
       },

         {
         headerName: 'Pull From Webarchive',
         valueFormatter: superUserFormatter,
         field: 'from_webarchive',
         cellStyle: {
           textAlign: 'center',
         },
       },

        {
         headerName: 'Created At',
         field: 'created_at_formatted',
         cellStyle: {
           textAlign: 'center',
         },
       },
        {
          headerName: 'Actions',
          cellRenderer: 'actionsRenderer',
          cellRendererParams: {
            info: this.currentUserAccountInfo,
            edit: this.editStatement.bind(this),
            deleteS: this.deleteCompany.bind(this)
          },
          cellStyle: {
           textAlign: 'center',
         },
        }
     ];
    },
  },
  methods: {
     deleteConfirm(){
      this.$refs['my-modal-2'].hide()
      this.$store
          .dispatch(DELETE_TUSER, this.selectedPk)
          .then((data) => {
            this.hideModal2()
            this.loadCompanies()
          });
    },
     hideModal() {
       this.$refs['my-modal'].hide()
     },
    deleteCompany(id){
     this.$refs['my-modal-2'].show()
     this.selectedPk = id;
    },
     hideModal2() {
       this.$refs['my-modal-2'].hide()
     },
    showModal() {
      this.formData = {
        'name': null,
        'abbr': null,
        'from_twitter_api': null,
        'from_webarchive': null,
      };
       this.edit_or_create = true;
       this.$refs['my-modal'].show()
    },
     editStatement(data){
      this.formData = {
        'pk' : data.pk,
        'name' : data.name,
        'abbr' : data.abbr,
        'from_twitter_api': data.from_twitter_api,
        'from_webarchive': data.from_webarchive,
      };
       this.edit_or_create = false;
       this.$refs['my-modal'].show()
    },
    submitForm(){
       if(this.edit_or_create){
         this.$store
          .dispatch(CREATE_TUSER, this.formData)
          .then((data) => {
            this.hideModal()
            this.loadCompanies()
          });
       }
       else{
         this.$store
          .dispatch(UPDATE_TUSER, this.formData)
          .then((data) => {
            this.hideModal()
            this.loadCompanies()
          });
       }
     },
    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.gridColumnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.loadCompanies();
     },
    loadCompanies(){
        this.gridApi.showLoadingOverlay();
         this.$store
          .dispatch(GET_TUSERS)
          .then((data) => {
            this.items = data;
            this.members_count = this.items.length;
            this.gridApi.hideOverlay();
          })
      }
  },
  beforeMount() {
     this.frameworkComponents = {
       'actionsRenderer': TuserActionsRenderer,
     };
  },
  created() {
  },
  watch: {
    search_text(new_value, old_value) {
      this.gridApi.setQuickFilter(new_value);
    },
  }
};
window.statusFormatter = function superUserFormatter(params) {
  if(params.value) return params.value;
  return '-';
};

window.superUserFormatter = function superUserFormatter(params) {
  if(params.value) return "Yes";
  return 'No';
};

</script>
<style>
.ag-header-cell-label {
   justify-content: center;
}
.btn-create {
  margin-left: 10px;
  border-radius: 33px;
  height: 28px;
  padding: 0rem 1rem !important;
}
.btn-create i {
  font-size: 1rem !important;
  padding-right: 0.1rem !important;
}
.modal-input {
  height: 45px !important;
}
.ag-grid-vue {
  width: 100% !important;
  height: calc(100vh - 230px) !important;
}

/* This CSS is to not apply the border for the column having 'no-border' class */
.no-border.ag-cell:focus {
  border: none !important;
  outline: none;
}
.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}

.ag-row-selected {
  background-color: transparent !important;
}
.ag-theme-material .ag-details-row {
  padding: 10px 40px 10px 40px !important;
}

div.ag-theme-material div.ag-row {
  font-size: 15px !important;
  padding-top: 10px !important;
}
.container {
  max-width: 98vw !important;
}
</style>
