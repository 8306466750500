<template>
    <div v-if="params.data">
        <a href="#" class="btn btn-icon btn-outline-primary btn-xs mr-2" @click="editSymbol">
          <i class="flaticon2-edit"></i>
        </a>
      <a  v-if="params.info.admin" href="#" class="btn btn-icon btn-outline-danger btn-xs" @click="deleteSymbol">
        <i class="flaticon2-delete"></i>
      </a>
    </div>
</template>

<script>


    export default {
        name: "ActionsRenderer",
        components: {
        },
        props: {
            params: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        methods: {
          editSymbol(){
           this.params.edit(this.params.data);
          },
          deleteSymbol(){
            this.params.deleteS(this.params.data.pk ? this.params.data.pk : this.params.data.id);
          }
        },
       computed: {
       },
    }
</script>

